import React from "react"
import styled from "styled-components"
import Loadable from "react-loadable"

// Styling
import CSSReset from "../styles/reset"
import GlobalStyle from "../styles/"

// Components
import Lights from "./Lights"
import Background from "./Background"
import Reindeer from "../components/Reindeer"
import Balloons from "../components/Balloons"

interface IProps {
  children: React.ReactNode
}

const Layout = ({ children }: IProps) => {
  const LoadableSnow = Loadable({
    loader: () => import("./Snow"),
    loading() {
      return <div>Loading...</div>
    },
  })

  return (
    <>
      <CSSReset />
      <GlobalStyle />
      <LoadableSnow />
      <Background />
      <Lights />
      <Reindeer />
      <Balloons />
      <Container>
        <main>{children}</main>
      </Container>
    </>
  )
}
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto;
  padding: calc(74px + 24px) 24px 24px;

  > main {
    margin: 0 auto;
  }
`

export default Layout
