import React from "react"
import styled from "styled-components"

// Images
import backgroundImage from "../images/background.jpg"

const Background = () => <Container />

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: right center;
`

export default Background
