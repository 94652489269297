import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"

const Lights = () => (
  <Container>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </Container>
)

const Container = styled.ul`
  @keyframes flash-1 {
    0%,
    100% {
      background: rgba(255, 254, 229, 1);
      box-shadow: 0px calc(28px / 6) calc(12px * 2) 3px rgba(240, 180, 18, 1);
    }
    50% {
      background: rgba(255, 254, 229, 0.4);
      box-shadow: 0px calc(28px / 6) calc(12px * 2) 3px rgba(240, 180, 18, 0.2);
    }
  }
  @keyframes flash-2 {
    0%,
    100% {
      background: rgba(236, 253, 242, 1);
      box-shadow: 0px calc(28px / 6) calc(12px * 2) 3px rgba(8, 220, 115, 1);
    }
    50% {
      background: rgba(236, 253, 242, 0.4);
      box-shadow: 0px calc(28px / 6) calc(12px * 2) 3px rgba(8, 220, 115, 0.2);
    }
  }
  @keyframes flash-3 {
    0%,
    100% {
      background: rgba(255, 248, 248, 1);
      box-shadow: 0px calc(28px / 6) calc(12px * 2) 3px rgba(213, 0, 13, 1);
    }
    50% {
      background: rgba(255, 248, 248, 0.4);
      box-shadow: 0px calc(28px / 6) calc(12px * 2) 3px rgba(213, 0, 13, 0.2);
    }
  }

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;

  > li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: calc(40px / 2);
    display: inline-block;
    background: rgba(255, 254, 229, 1);
    box-shadow: 0px 28px / 6 12px * 2 3px rgba(240, 180, 18, 1);
    animation-name: flash-1;
    animation-duration: 2s;

    :nth-child(2n + 1) {
      background: rgba(236, 253, 242, 1);
      box-shadow: 0px 28px / 6 12px * 2 3px rgba(8, 220, 115, 0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }

    :nth-child(4n + 2) {
      background: rgba(255, 248, 248, 1);
      box-shadow: 0px 28px / 6 12px * 2 3px rgba(213, 0, 13, 1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }

    :nth-child(odd) {
      animation-duration: 1.8s;
    }

    :nth-child(3n + 1) {
      animation-duration: 1.4s;
    }

    :before {
      content: "";
      position: absolute;
      background: ${colors.black};
      width: calc(12px - 2px);
      height: calc(28px / 3);
      border-radius: 3px;
      top: calc(0px - (28px / 6));
      left: 1px;
    }

    :after {
      content: "";
      top: calc(0px - 28px / 2);
      left: calc(12px - 3px);
      position: absolute;
      width: calc(40px + 12px);
      height: calc((28px / 3 * 2));
      border-bottom: solid ${colors.black} 2px;
      border-radius: 50%;
    }

    :last-child:after {
      content: none;
    }

    :first-child {
      margin-left: -40px;
    }
  }
`

export default Lights
