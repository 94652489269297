export default {
  white: "#ffffff",
  black: "#2C2C2C",
  grey: "#D8D8D8",
  red: "#E61B30",
  redDark: "#CC172A",
  yellow: "#F6EA03",
  green: "#337038",
  greenDark: "#245028",
}
