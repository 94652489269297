// Global stylesheet
import { createGlobalStyle } from "styled-components"

// Styling
import colors from "./colors"
import textStyles from "./textStyles"
import { fontFaces } from "./fonts"

const GlobalStyle = createGlobalStyle`
  ${fontFaces};

  html,
  body {
    width: 100%;
    height: 100%;
    ${textStyles.body};
    margin: 0;
    color: ${colors.white};
    background-color: ${colors.white};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${colors.red};
  }

  p {
    ${textStyles.body};
  }
`

export default GlobalStyle
