import React, { useContext } from "react"
import styled, { css } from "styled-components"

// Context
import Context from "../context/context"

// Images
import reindeerBodyImage from "../images/reindeer-body.png"
import reindeerFrontImage from "../images/reindeer-front.png"
import reindeerBackImage from "../images/reindeer-back.png"
import reindeerSparklesImage from "../images/reindeer-sparkles.gif"
import marikiImage from "../images/mariki.png"

const Reindeer = () => {
  const app = useContext(Context)
  return (
    <Container active={app !== null && app.party}>
      <Sparkles src={reindeerSparklesImage} alt="Reindeer sparkles" />
      <Front src={reindeerFrontImage} alt="Reindeer front" />
      <Back src={reindeerBackImage} alt="Reindeer back" />
      <Body src={reindeerBodyImage} alt="Reindeer body" />
      <Mariki src={marikiImage} alt="Mariki" />
    </Container>
  )
}

const Container = styled.div`
  z-index: 10;
  position: fixed;
  top: 120px;
  right: -300px;
  width: 300px;
  height: 300px;
  transition: transform 2.4s linear;

  ${({ active }: { active: boolean }) =>
    active &&
    `
    transform: translateX(-95vw);
  `};
`

const imageStyling = css`
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
`

const Front = styled.img`
  @keyframes frontLegs {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(60deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  ${imageStyling};
  left: 0;
  animation: frontLegs 1.4s ease-in-out infinite;
`

const Back = styled.img`
  @keyframes backLegs {
    0% {
      transform: rotate(0) translateY(0);
    }
    50% {
      transform: rotate(-45deg) translateY(20%);
    }
    100% {
      transform: rotate(0) translateY(0);
    }
  }
  ${imageStyling};
  right: 0;
  animation: backLegs 1.4s ease-in-out infinite;
`

const Body = styled.img`
  ${imageStyling};
  left: 0;
`

const Sparkles = styled.img`
  position: absolute;
  left: 90%;
  top: 50%;
  transform: translateY(-50px);
  margin-bottom: 0;
  height: 60px;
  width: 500px;
`

const Mariki = styled.img`
  position: absolute;
  top: -80px;
  right: -80px;
  width: 250px;
  margin-bottom: 0;
`

export default Reindeer
