import { css } from "styled-components"

// Styling
import { fonts } from "./fonts"
import mq from "./breakpoints"

// Text styles
export default {
  pageTitle: css`
    margin: 0;
    font-family: ${fonts.secondary};
    font-size: 60px;
    font-weight: 700;
    line-height: 100px;

    ${mq.from.breakpoint.M`
      font-size: 80px;
      line-height: 180px;
    `};
  `,
  dayTitle: css`
    margin: 0;
    font-family: ${fonts.secondary};
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
  `,
  frontCardTitle: css`
    margin: 0;
    font-family: ${fonts.secondary};
    font-size: 74px;
    font-weight: 700;
    line-height: 100px;
  `,
  frontCardSubtitle: css`
    margin: 0;
    font-family: ${fonts.secondary};
    font-size: 40px;
    font-weight: 700;
    line-height: 100px;
  `,
  cardCornerTitle: css`
    margin: 0;
    font-family: ${fonts.secondary};
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
  `,
  title: css`
    margin: 0 0 24px;
    font-family: ${fonts.secondary};
    font-size: 38px;
    font-weight: 700;
    line-height: 1.125;

    ${mq.from.breakpoint.S`
      font-size: 58px;
    `};
  `,
  body: css`
    margin: 0 0 16px;
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
  `,
}
