import React, { useContext } from "react"
import styled from "styled-components"

// Context
import Context from "../context/context"

// Images
import casperImage from "../images/casper.png"

const Balloons = () => {
  const app = useContext(Context)

  return (
    <Container active={app !== null && app.party}>
      <Image src={casperImage} alt="Casper = party" />
    </Container>
  )
}

const Container = styled.div`
  z-index: 10;
  position: fixed;
  bottom: -620px;
  right: 5vw;
  height: 600px;
  transition: transform 2.4s linear;

  ${({ active }: { active: boolean }) =>
    active &&
    `
    transform: translateY(-95vh);
  `};
`

const Image = styled.img`
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  width: 100%;
  margin-bottom: 0;
  animation: float 2s ease-in-out infinite;
`

export default Balloons
