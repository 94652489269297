import { css } from "styled-components"

// Fonts
const fonts = {
  primary: "'Barlow', sans-serif",
  secondary: "'Mountains of Christmas', cursive",
}

const fontFaces = css`
  @import url("https://fonts.googleapis.com/css?family=Barlow&display=swap");
  @import url("https://fonts.googleapis.com/css?family=Mountains+of+Christmas:400,700&display=swap");
`

export { fonts, fontFaces }
